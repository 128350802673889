import React, {useEffect, useState} from "react";
import styled from "styled-components";
import imgCloseX from "@@/assets/icons/inputX.png";
import imgLogo from "@@/assets/metablox-logo.png";

type PropsStyled = {
    width?:string,
    height?:string,
};

const PopupGroup = styled.div.attrs((props:PropsStyled) => ({
    width: props?.width || "600px",
    height: props?.height || "300px",
}))`
  display: flex;
  flex-direction: column;
  background: #0f0035;
  border: 1px solid rgba(255, 255, 255, 0.37);
  box-shadow: -6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: ${(props) => props?.width};
  height: ${(props) => props?.height};
  //align-items: center;
  
  .popup_header{
    display: flex;
    flex-direction: row-reverse;
    padding: 21px;
  }
  .popup_title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
  }
  .popup_content{
    display: flex;
    flex-direction: row;
    justify-content: center;

    div{
      width: 400px;
      text-align: center;
    }
  }

  .imgCloseX{
    width: 14px;
  }
  .imgLogo{
    height: 45px;
  }
`;

type Props = {
    closePopup?:any;
    msgContent?:string;
};
const PopupAlert = ({closePopup = null,msgContent = ''}:Props) => {

    return (
        <PopupGroup>
            <div className="popup_header">
                <img src={imgCloseX} alt="imgCloseX" className="imgCloseX cursor-pointer"  onClick={closePopup}/>
            </div>
            <div className="popup_title">
                <img src={imgLogo} alt="imgLogo" className="imgLogo "/>
            </div>

            <div className="popup_content">
                <div>{msgContent}</div>
            </div>


        </PopupGroup>

    );
};

export default PopupAlert;
