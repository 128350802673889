import React from "react";
import styled from "styled-components";

type PropsStyled = {
  padding?:string,
  margin?:string;
  width?:string;
  font_size?: string;
  line_height?: string;
  font_weight?: string;
  color?: string;
  background?: string;
  disable_opacity?: string;
  border_radius?: string;
  border?: string;
};

const SubmitButton = styled.div.attrs((props:PropsStyled) => ({
  padding: props?.padding || "16px 48px",
  margin: props?.margin || "0 0 0 0",
  width: props?.width || "363px",
  font_size: props?.font_size || "27px",
  line_height: props?.line_height || "37px",
  font_weight: props?.font_weight || "500",
  color: props?.color || "rgba(255, 255, 255, 1)",
  background: props?.background || "linear-gradient(324.05deg, rgba(160,49,228,1) -8.98%, rgba(115, 87, 231, 1) 31.12%, rgba(78, 171, 240, 1) 98.56%)",
  disable_opacity: props?.disable_opacity || "0.42",
  border_radius: props?.border_radius || "48px",
  border: props?.border || "#267FF5 0px solid",
}))`

  .submit_button {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props?.padding};
    margin: ${(props) => props?.margin};
    width: ${(props) => props?.width};
    
    /* blue to purple 45 degree */
    border: ${(props) => props?.border};
    background: ${(props) => props?.background};
    border-radius: ${(props) => props?.border_radius};
    cursor: pointer;

    div {
      font-style: normal;
      font-size: ${(props) => props?.font_size};
      line-height: ${(props) => props?.line_height};
      font-weight: ${(props) => props?.font_weight};
      display: flex;
      align-items: center;
      text-align: center;

      /* white */
      color: ${(props) => props?.color};
    }
  }
  .submit_button_disable {
    cursor: not-allowed;
    opacity: ${(props) => props?.disable_opacity};
  }
`;

type Props = {
  SubmitClick?:any;
  allowClick?:any;
  title?:any;
  cssStyle?:{
    padding?:string,
    margin?:string;
    width?:string;
    height?:string;
    font_size?: string;
    line_height?: string;
    font_weight?: string;
    color?: string;
    background?: string;
    disable_opacity?: string;
    border_radius?: string;
    border?:string
  }
};
const Button = ({
                  SubmitClick = null,
                  allowClick = true,
                  title = 'Submit',
                  cssStyle
}:Props) => {
  return (
      <SubmitButton
          padding={cssStyle?.padding}
          margin={cssStyle?.margin}
          width={cssStyle?.width}
          // height={cssStyle?.height}
          font_size={cssStyle?.font_size}
          line_height={cssStyle?.line_height}
          font_weight={cssStyle?.font_weight}
          color={cssStyle?.color}
          background={cssStyle?.background}
          disable_opacity={cssStyle?.disable_opacity}
          border_radius={cssStyle?.border_radius}
          border={cssStyle?.border}
          >
        {(allowClick && SubmitClick !== null)
            ? (
                <div className="submit_button" onClick={SubmitClick}>
                  <div>{title}</div>
                </div>
            )
            :(
                <div className="submit_button submit_button_disable">
                  <div>{title}</div>
                </div>
            ) }
      </SubmitButton>

  );
};

export default Button;
