import React from "react";
import styled from "styled-components";

const MainGroup = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.8);
  //margin: 110px;
`;

type Props = {
  children: React.ReactNode;
};

const MainContent = ({ children }: Props) => {
  return <MainGroup>{children}</MainGroup>;
};

export default MainContent;
