// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAnalytics} from "firebase/analytics"
import {getAuth} from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
let firebaseConfig = {}
if((window.location.origin).indexOf("metablox.io") != -1){
    //prod or staging
    firebaseConfig = {
        // apiKey: "AIzaSyCri-s68z5yZEh4p1TM7CKYdVaP7Y-9LZA",
        // authDomain: "metabloxmerchantpro.firebaseapp.com",
        // projectId: "metabloxmerchantpro",
        // storageBucket: "metabloxmerchantpro.appspot.com",
        // messagingSenderId: "517942193014",
        // appId: "1:517942193014:web:1690c6d7d7b34a10ed6857",
        // measurementId: "G-PB7X6R3S8W"

        apiKey: "AIzaSyAun_1_HZnG6zTID4uHXhzfhsPZVnO2TEY",
        authDomain: "metabloxapppro.firebaseapp.com",
        projectId: "metabloxapppro",
        storageBucket: "metabloxapppro.appspot.com",
        messagingSenderId: "478843135962",
        appId: "1:478843135962:web:3525e2cc1d942cee2782e3",
        measurementId: "G-JVQN6ZF813"

    };
}
else {
    //test
     firebaseConfig = {
        apiKey: "AIzaSyDH4_Zvs-zEBqSexmgslTELOUDSfyRGgYU",
        authDomain: "metabloxv11.firebaseapp.com",
        projectId: "metabloxv11",
        storageBucket: "metabloxv11.appspot.com",
        messagingSenderId: "571490917989",
        appId: "1:571490917989:web:887b0bf42570e074de308c"
    };
}
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp);
export default  firebaseApp