import {ReactComponent as IconMenuHome} from "@@/assets/svg-icons/menu-home.svg";
import {ReactComponent as IconMenuWifi} from "@@/assets/svg-icons/menu-wifi.svg";
import {ReactComponent as IconMenuCustomer} from "@@/assets/svg-icons/menu-customer.svg";
import {ReactComponent as IconMenuSettings} from "@@/assets/svg-icons/menu-settings.svg";
import React from "react";

export const router =
    [
      {label:'Dashboard',code: 'dashboard',key:'/',icon:<IconMenuHome/>,allowedroles:['admin']},
      {label:'Wi-Fi',code: '',key:'/wifi',icon:<IconMenuWifi />,allowedroles:['admin']
        ,children:[
              {label:'WiFi Connections',code: 'wifi-connections',key:'/wifi/wifi-connections',icon:'',allowedroles:['admin']},
              // {label:'Free WiFi',code: 'wifi',key:'/wifi/free-wifi',icon:'',allowedroles:['admin']},
          // {label:'OpenRoaming',code: 'wifi',key:'/wifi/open-roaming',icon:'',allowedroles:['admin']},
        ]
      },
      {label:'Customer',code: '',key:'/customer',icon:<IconMenuCustomer/>,allowedroles:['admin']
        ,children:[
          {label:'Check In History',code: 'check-in-history',key:'/customer/check-in-history',icon:'',allowedroles:['admin']},
          {label:'Guest List',code: 'guest-list',key:'/customer/guest-list',icon:'',allowedroles:['admin']},
        ]
      },
      {label:'Settings',code: '',key:'/settings',icon:<IconMenuSettings/>,allowedroles:['admin']
        ,children:[
          {label:'Account',code: 'account',key:'/settings/account',icon:''},
          {label:'WiFi Settings',code: 'wifi',key:'/settings/wifi',icon:'',allowedroles:['admin']},
        ]
      },
    ]
//     [
//   // { name: 'Login',path:'/login',code: 'login' },
//   { name: 'Account',path:'/account', code: 'account' },
//   { name: 'WiFi',path:'/wifi', code: 'wifi',allowedroles:['admin'] },
//   { name: 'Check In History',path:'/user/check-in-history', code: 'check-in-history',allowedroles:['admin'] },
//   { name: 'WiFI Connections',path:'/user/wifi-connections', code: 'wifi-connections',allowedroles:['admin'] },
//   { name: 'Guest List',path:'/user/contact-list', code: 'contact-list',allowedroles:['admin'] },
// ];
