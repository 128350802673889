import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {getApplyDetail, getUserInfo} from "@@/utils/request/api";
import sdstorage from "@@/utils/db/localStorage";
import { ReactComponent as IconMenuHome } from "@@/assets/svg-icons/menu-home.svg";

import { ReactComponent as IconMenuWifi } from "@@/assets/svg-icons/menu-wifi.svg";
import { ReactComponent as IconMenuSettings } from "@@/assets/svg-icons/menu-settings.svg";
import { ReactComponent as IconMenuCustomer } from "@@/assets/svg-icons/menu-customer.svg";
import React, {ReactNode} from "react";
import {
  UserOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {router} from "@@/router";

dayjs.extend(utc);

export function toPascalCase(str: string) {
  return str
      .split('-')
      .map((w) => w[0].toUpperCase() + w.slice(1).toLowerCase())
      .join('');
}

// /* Check whether it is a mobile access */
// export function _isMobile() {
//   return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
// }
/**
 *  Hiding string
 *
 *  str         String to be processed
 *  start_len    The first few reserved
 *  end_len      Reserved last few digits
 *  symbol         Replaced string
 *  replace_len      How many characters to replace with the previously defined characters(Not transmitted or <=0, will be replaced with an equal number of characters)
 * */
export function hideStr(str = '', start_len = 1, end_len = 1,symbol = '*',replace_len = -1) {
  let len = replace_len <= 0 ? str.length - start_len - end_len :replace_len;
  let replace_symbol = '';
  for (let i = 0; i < len; i++) {
    replace_symbol += symbol;
  }
  return str.substring(0, start_len) + replace_symbol + str.substring(str.length - end_len);
}

/* Json String to Object */
export function json_to_obj(_data_ :any) {
  if(typeof _data_ === 'object'){
    return _data_;
  }
  if(!_data_ || typeof _data_ !== 'string'){
    return {};
  }

  const pattern = '&quot;';
  if(_data_.indexOf(pattern)!==-1){
    _data_ = _data_.replace(new RegExp(pattern, "gm"), '"');
  }
  return JSON.parse(_data_);

  /*if(type === 'local'){
    return eval('(' + _data_ + ')');
  }
  else{
    const json_str = _data_.replace(new RegExp('&quot;', "gm"), '"');
    return JSON.parse(json_str);
  }*/
}

/* The list array is sorted according to the fields of the objects in it */
export function arrListSort(arrList:any[],key:string = 'id'){
  const handle = (property:any) => {
    return function(a:any,b:any){
      const val1 = a[property];
      const val2 = b[property];
      return val1 - val2;
    }
  }
  return arrList.sort(handle(key));
}

/* Judge whether it is empty */
export function empty(value:any) :boolean{
  return typeof value === 'undefined' || value === null || value === false || value.length === 0;
}

export function array_column(array:any[], field:string|null,fid:string = 'id'):any {
  if(field !== null){
    return array.map(v => v[field]);
  }
  else{
    let obj:any = {};
     array.map(v => {
      obj[v[fid]] = v;
      return v;
    });
    return obj;
  }
}

// export function explode(str,separator){
//   return str.split(separator)
// }
// export function implode(array,separator){
//   return array.join(separator)
// }
//
//
/* The seconds are optimized and displayed as days,hours,minutes */
export function timeToFriendly(time = 0) {
  return  time + ' Seconds'
  let days = parseInt((time / ( 60 * 60 * 24)).toString());
  let hours = parseInt(((time % ( 60 * 60 * 24)) / ( 60 * 60)).toString());
  let minutes = parseInt(((time % (60 * 60)) / ( 60)).toString());
  return days + " Days, " + hours + " Hours, " + minutes + " Minutes";

  // if(days > 0){
  //   return days + " Days, " + hours + " Hours, " + minutes + " Minutes";
  // }
  // else if(hours > 0){
  //   return hours + " Hours, " + minutes + " Minutes";
  // }
  // else {
  //   return minutes + " Minutes";
  // }
}

export function conversionUtcToDate(date:string, type:string = 'local') {
  if (type === 'local') {
    // Pass in local to convert UTC time to local time
    return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  } else if (type === 'UTC') {
    // Convert the time to UTC when it is passed in to UTC
    return dayjs(date).utc().format()
  }
}

export function conversionTimestampToDate(time:number, type:string = 'local') {
  if (type === 'local') {
    // Pass in local to convert UTC time to local time
    return dayjs.unix(time).local().format('YYYY-MM-DD HH:mm:ss')
  } else if (type === 'UTC') {
    // Convert the time to UTC when it is passed in to UTC
    return dayjs(time).utc().format()
  }
}

//取得[n,m]范围随机数
export function randomNum(n:number,m:number):number {
  let result = Math.random()*(m+1-n)+n;
  while(result>m) {
    result = Math.random()*(m+1-n)+n;
  }
  return Math.ceil(result);
}


function md5(value:any) {
  return value;
}

const localStorageKey = 'sdata';

/* set localStorage */
function setLocal(key:string = '',value:any){
  let vv:any = {};
  vv[localStorageKey] = typeof value === "undefined" ?"undefined":value;
  key = md5(key);
  return localStorage.setItem(key, JSON.stringify(vv));
}

/* delete localStorage */
function deleteLocal(key = ''){
  key = md5(key);
  return localStorage.removeItem(key);
}

function clearLocal(){
  return localStorage.clear();
}

export const clearAccount = () => {
  const remember_password = sdstorage.get('remember_password');
  clearLocal();
  sdstorage.save('remember_password',remember_password)
  return true;

}


/* get localStorage */
export function getLocal(key = ''){
  //When the incoming key is incorrect, an empty string is returned directly
  if(typeof key !== 'string' || key === ''){
    return '';
  }

  key = md5(key);
  //When the obtained data is null or does not contain the specified string, null is returned directly
  let result = localStorage.getItem(key);
  if(result === null || result.indexOf(localStorageKey) === -1){
    return null;
  }

  //The json string is parsed into an object
  let data = json_to_obj(result)[localStorageKey];
  let undef;
  return data === "undefined"?undef: data;
}

/* set JWTToken  */
export function setJWTToken(value:any){
  return setLocal('JWTToken',value);
}

/* get JWTToken */
export function getJWTToken(){
  return getLocal('JWTToken');
}


/* getLocalUserInfo */
export function getLocalUserInfo(){
  return getLocal('userInfo');
}

/* setLocalUserInfo */
export function setLocalUserInfo(value:any){
  return setLocal('userInfo',value);
}

/* delLocalUserInfo */
export function delLocalUserInfo(){
  return deleteLocal('userInfo');
}

/* getLocalDictData */
export function getLocalDictData(){
  return getLocal('DictData');
}

/* setLocalDictData */
export function setLocalDictData(value:any){
  const data:any = {}
  for (const kk in value) {
    data[value[kk].type] = value[kk].list
  }
  return setLocal('DictData',data);
}


/* getLocalStoreInfo */
export function getLocalStoreInfo(){
  return sdstorage.get('StoreInfo');
}
/* getLocalStoreInfo */
export function getLocalStoreInfo1(){
  return getLocal('StoreInfo');
}


/* setLocalStoreInfo */
export function setLocalStoreInfo(value:any,time : number|null = null){
  return sdstorage.save('StoreInfo',value,time);
}

/* delLocalStoreInfo */
export function delLocalStoreInfo(){
  return deleteLocal('StoreInfo');
}

export function copy_text (content:string) {
  content = content === null ? '':content;
  if(typeof (navigator.clipboard) != 'undefined' && window.isSecureContext){
    navigator.clipboard.writeText(content);
    if(content === ''){
      return false;
    }
    else{
      return true;
    }
  }
  else{
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value',content );
    document.body.appendChild(input);
    input.select();
    let re = document.execCommand('copy');
    document.body.removeChild(input);
    if (re) {
      return true;
    }
    else{
      return false;
    }
  }
}

export async function cacheUserInfo(){
  const user_info = getLocalUserInfo();
  if(!user_info){
    const res = await getUserInfo();
    if(res?.code !== 200){
      return {}
    }

    // sdstorage.save(key,currency);
    res.data.role = 'admin';
    setLocalUserInfo(res?.data)
    return res?.data;
  }
  return user_info;
}
//
// export async function cacheApplyStatus(){
//   const res1 = getLocalApplyStatus();
//   if(!res1){
//     const res = await getApplyDetail();
//     if(res?.code !== 200){
//       return false;
//     }
//
//     if(res?.data.approved === false){
//       setLocalApplyStatus(res?.data.approved,60)
//     }
//     else {
//       setLocalApplyStatus(res?.data.approved)
//     }
//     return res?.data?.approved;
//   }
//   return res1;
// }

export async function cacheStoreInfo(){
  const res0 = getLocalStoreInfo();
  if(res0 !== ''){
    return res0
  }
  // const navList1 = [
  //   {title:'Dashboard',url:'/',nav:'dashboard',icon:'home'},
  //   {title:'Wi-Fi',url:'/wifi',nav:'wifi',icon:'wifi'},
  //   {title:'User',url:'/user',nav:'user',icon:'user'
  //     ,child:[
  //       {title:'Check In History',url:'/user/check-in-history',nav:'user',icon:''},
  //       {title:'WiFI Connections',url:'/user/wifi-connections',nav:'user',icon:''},
  //       {title:'Contact List',url:'/user/contact-list',nav:'user',icon:''},
  //     ]
  //   },
  //   {title:'Account',url:'/account',nav:'account',icon:'setting'},
  // ]
  // const navList0 = [
  //   {title:'Account',url:'/account',nav:'account',icon:'setting'},
  // ]

  const res = await getApplyDetail();
  if(res?.code !== 200){
    return false;
  }

  let store_data = res?.data;
  setLocalStoreInfo(store_data,3*60*60)
  return store_data;
}

export const menuList = (type = 1) => {
  if(type === 1){
   return router
  }
  else {
    return [{label:'Account',key:'/settings/account',icon:<IconMenuSettings/>}]
  }

}


export const merchantCurrency = () => {
  // return "CAD";
  return "Points";
}

export const HarmonyToken = () => {
  return "Harmony ONE";
}


export const HarmonyTokenSymbol = () => {
  return "ONE";
}

//Regular check email
export function regEmail(email:any = ''){
  
  // let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  // eslint-disable-next-line no-useless-escape
  const reg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
  if(reg.test(email)){
    return {status:true,msg:'Verification passed.'};
  }
  else{
    return {status:false,msg:'Invalid email address format. Please enter again.'};
  }
}

//Regular check url
export function regUrl(url:any = ''){
  // const urlReg = /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
  let urlReg = /^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  if(urlReg.test(url)){
    return {status:true,msg:'Url passed.'};
  }
  else{
    return {status:false,msg:'Please fill in the correct url format: http://aa.com or https://aa.com'};
  }
}


export function api_url() {
  if (typeof window !== 'undefined') {
    let wUrl;
    const port = window.location.port;
    if (window.location.origin.indexOf('merchant.metablox.io') != -1) {
      if (port == '80' || port == '443' || port == '') {
        //prod
        wUrl = 'https://api.metablox.io';
      } else {
        //stg
        wUrl = 'https://apistage.metablox.io';
      }
    }
    else if (window.location.origin.indexOf('54.185.72.94') != -1) {
      //test - QA
      wUrl = 'https://apitest.metablox.io';
    }
    else if (window.location.origin.indexOf('54.189.68.105') != -1) {
      //dev
      wUrl = 'https://apidev.metablox.io/';
    }
    else {
      wUrl = ''
    }
    return wUrl + '/v1/merchant';
  } else {
    return '/v1/merchant';
  }
}


export function form_validation(_data:any = {},rules:any = {}) {
  const validateData:any = {}
  let validate = true
  for (const key in _data) {
    validateData[key] = form_value_validation(_data[key],rules[key])
    if(validateData[key].error){
      validate = false
    }

    // const value = _data[key]
    // const data1 = rules[key]
    // validateData[key] = {
    //   error:false,
    //   message:'',
    // }
    // if(rules[key]){
    //   for (const key1 in data1) {
    //     const value1 = data1[key1]
    //     if(value1?.required && value1?.required === true && ((value ?? '').toString())?.length <= 0){
    //       validateData[key].error = true
    //       validateData[key].message = value1?.message ?? 'error'
    //     }
    //     if(!validateData[key].error && value1?.minLen && ((value ?? '').toString())?.length < value1?.minLen){
    //       validateData[key].error = true
    //       validateData[key].message = value1?.message ?? 'error'
    //     }
    //     if(!validateData[key].error && value1?.maxLen && ((value ?? '').toString())?.length > value1?.maxLen){
    //       validateData[key].error = true
    //       validateData[key].message = value1?.message ?? 'error'
    //     }
    //
    //     if(!validateData[key].error && ((value ?? '').toString())?.length > 0 && value1?.reg && !value1?.reg.test(value ?? '')){
    //       validateData[key].error = true
    //       validateData[key].message = value1?.message ?? 'error'
    //     }
    //     if(validateData[key].error){
    //       validate = false
    //     }
    //
    //   }
    // }
  }

  return {validate:validate,validateData:validateData}
}

export function form_value_validation(value:any,rulesArr:any = []) {
  const validateData:any = {
    error:false,
    message:'',
  }
  if(rulesArr){
    for (const key1 in rulesArr) {
      const value1 = rulesArr[key1]
      if(value1?.required && value1?.required === true && ((value ?? '').toString())?.length <= 0){
        validateData.error = true
        validateData.message = value1?.message ?? 'error'
      }
      if(!validateData.error && value1?.minLen && ((value ?? '').toString())?.length < value1?.minLen){
        validateData.error = true
        validateData.message = value1?.message ?? 'error'
      }
      if(!validateData.error && value1?.maxLen && ((value ?? '').toString())?.length > value1?.maxLen){
        validateData.error = true
        validateData.message = value1?.message ?? 'error'
      }
      if(!validateData.error  && ((value ?? '').toString())?.length > 0 && value1?.reg && !value1?.reg.test(value ?? '')){
        validateData.error = true
        validateData.message = value1?.message ?? 'error'
      }
    }
  }

  return validateData
}


export const data_dictionary = (key:string) => {
  return getLocalDictData()[key] ?? []
}


export const EyeOutlined =
    <span role="img" aria-label="eye-invisible" tabIndex={-1} className="anticon anticon-eye-invisible ant-input-password-icon">
             <svg className='cursor-pointer' width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.1383C7.4831 11.1383 6.97763 11.0671 6.48801 10.9414L7.953 9.18795C7.96866 9.18817 7.98433 9.18828 8 9.18828C8.88406 9.18828 9.73191 8.84587 10.357 8.23638C10.9822 7.62688 11.3333 6.80023 11.3333 5.93827C11.3333 5.70098 11.3067 5.46565 11.2546 5.23629L13.1819 2.9296C14.9062 4.35043 16 5.93827 16 5.93827C16 5.93827 12.4178 11.1383 8 11.1383ZM7.3497 2.75073C7.6203 2.69825 7.89503 2.679 8.16769 2.6924L9.6134 0.962064C9.09226 0.819663 8.55267 0.738281 8 0.738281C3.58222 0.738281 0 5.93827 0 5.93827C0 5.93827 1.12502 7.57137 2.89165 9.00716L4.77356 6.75475C4.64783 6.28244 4.63231 5.7866 4.73071 5.30424C4.85933 4.6738 5.1768 4.09471 5.64297 3.64019C6.10915 3.18566 6.7031 2.87613 7.3497 2.75073ZM11.4244 0.744025L3.42445 10.319L4.57555 11.2808L12.5756 1.70578L11.4244 0.744025Z" fill="#C4C4C4"/>
        </svg>
        </span>
export const EyeInvisibleOutlined =
    <span role="img" aria-label="eye-invisible" tabIndex={-1} className="anticon anticon-eye-invisible ant-input-password-icon">
             <svg className='cursor-pointer' width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.1383C7.4831 11.1383 6.97763 11.0671 6.48801 10.9414L7.953 9.18795C7.96866 9.18817 7.98433 9.18828 8 9.18828C8.88406 9.18828 9.73191 8.84587 10.357 8.23638C10.9822 7.62688 11.3333 6.80023 11.3333 5.93827C11.3333 5.70098 11.3067 5.46565 11.2546 5.23629L13.1819 2.9296C14.9062 4.35043 16 5.93827 16 5.93827C16 5.93827 12.4178 11.1383 8 11.1383ZM7.3497 2.75073C7.6203 2.69825 7.89503 2.679 8.16769 2.6924L9.6134 0.962064C9.09226 0.819663 8.55267 0.738281 8 0.738281C3.58222 0.738281 0 5.93827 0 5.93827C0 5.93827 1.12502 7.57137 2.89165 9.00716L4.77356 6.75475C4.64783 6.28244 4.63231 5.7866 4.73071 5.30424C4.85933 4.6738 5.1768 4.09471 5.64297 3.64019C6.10915 3.18566 6.7031 2.87613 7.3497 2.75073ZM11.4244 0.744025L3.42445 10.319L4.57555 11.2808L12.5756 1.70578L11.4244 0.744025Z" fill="#C4C4C4"/>
        </svg>
        </span>



export const IconX0 =
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.19266 5.00702C7.36705 6.1807 8.52306 7.32706 9.66592 8.48709C9.81794 8.6407 9.9267 8.83172 9.9812 9.04083C10.0647 9.40492 9.86139 9.75061 9.53929 9.9098C9.20352 10.0763 8.83675 10.018 8.5509 9.73432C7.71858 8.90774 6.88854 8.07852 6.06077 7.24668C5.71554 6.90046 5.3719 6.55214 5.01406 6.19173C4.92946 6.27001 4.85642 6.33201 4.79284 6.3982C3.69674 7.49343 2.60116 8.58954 1.50611 9.68652C1.24969 9.945 0.957539 10.0643 0.603907 9.96391C0.466684 9.92424 0.341962 9.84997 0.241716 9.74822C0.14147 9.64647 0.0690664 9.52067 0.0314502 9.38289C-0.00616612 9.24511 -0.00772994 9.09997 0.0269089 8.96141C0.0615477 8.82285 0.131226 8.69552 0.229256 8.59164C0.514054 8.28324 0.819867 7.99429 1.11728 7.6985L3.8817 4.93872C3.78081 4.88618 3.67677 4.85939 3.61057 4.79371C2.50711 3.69568 1.40365 2.59082 0.300192 1.49174C0.0406163 1.23378 -0.0676285 0.936948 0.0432428 0.585998C0.231881 -0.0197575 0.974352 -0.195233 1.44306 0.260266C2.00162 0.802977 2.54652 1.35935 3.09825 1.91099C3.72249 2.53671 4.3462 3.16348 4.98989 3.81232C5.0666 3.73981 5.13491 3.67887 5.20007 3.61372C6.30248 2.51184 7.40401 1.40856 8.50466 0.303873C8.76266 0.0438129 9.05797 -0.0638885 9.40897 0.038034C10.0043 0.214034 10.1972 0.951658 9.76681 1.41504C9.45679 1.74865 9.12575 2.0623 8.80417 2.38435L6.40809 4.78058C6.34241 4.84835 6.27778 4.91823 6.19266 5.00702Z" fill="#C4C4C4"/>
    </svg>
export const IconX =
    <span className="ant-input-clear-icon ant-input-clear-icon-has-suffix" role="button" tabIndex={-1}>
            <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.19266 5.00702C7.36705 6.1807 8.52306 7.32706 9.66592 8.48709C9.81794 8.6407 9.9267 8.83172 9.9812 9.04083C10.0647 9.40492 9.86139 9.75061 9.53929 9.9098C9.20352 10.0763 8.83675 10.018 8.5509 9.73432C7.71858 8.90774 6.88854 8.07852 6.06077 7.24668C5.71554 6.90046 5.3719 6.55214 5.01406 6.19173C4.92946 6.27001 4.85642 6.33201 4.79284 6.3982C3.69674 7.49343 2.60116 8.58954 1.50611 9.68652C1.24969 9.945 0.957539 10.0643 0.603907 9.96391C0.466684 9.92424 0.341962 9.84997 0.241716 9.74822C0.14147 9.64647 0.0690664 9.52067 0.0314502 9.38289C-0.00616612 9.24511 -0.00772994 9.09997 0.0269089 8.96141C0.0615477 8.82285 0.131226 8.69552 0.229256 8.59164C0.514054 8.28324 0.819867 7.99429 1.11728 7.6985L3.8817 4.93872C3.78081 4.88618 3.67677 4.85939 3.61057 4.79371C2.50711 3.69568 1.40365 2.59082 0.300192 1.49174C0.0406163 1.23378 -0.0676285 0.936948 0.0432428 0.585998C0.231881 -0.0197575 0.974352 -0.195233 1.44306 0.260266C2.00162 0.802977 2.54652 1.35935 3.09825 1.91099C3.72249 2.53671 4.3462 3.16348 4.98989 3.81232C5.0666 3.73981 5.13491 3.67887 5.20007 3.61372C6.30248 2.51184 7.40401 1.40856 8.50466 0.303873C8.76266 0.0438129 9.05797 -0.0638885 9.40897 0.038034C10.0043 0.214034 10.1972 0.951658 9.76681 1.41504C9.45679 1.74865 9.12575 2.0623 8.80417 2.38435L6.40809 4.78058C6.34241 4.84835 6.27778 4.91823 6.19266 5.00702Z" fill="#C4C4C4"/>
</svg>
            </span>
        </span>



export {}