import React, {useEffect, useState} from "react";
import {  Header } from "@@/components";
import styled from "styled-components";
import Card1 from "@@/components/common/Card1";
import BalanceCard from "@@/components/balance/BalanceCard";
import RewardsCard from "@@/components/rewards/RewardsCard";
import Card0 from "@@/components/common/Card0";
import {cacheUserInfo, conversionUtcToDate, merchantCurrency} from "@@/utils/function";
import {getBalanceHistory, getDashboardOverview} from "@@/utils/request/api";
import LeftMenu from "@@/components/account/LeftMenu";
import MainContent from "@@/components/layout/Main";
import IconCaretDown from "@@/assets/icons/dashboardCaretDown.png";
import IconCaretUp from "@@/assets/icons/dashboardCaretUp.png";
import IconChart1 from "@@/assets/icons/dashboardChart1.png";
import IconChart2 from "@@/assets/icons/dashboardChart2.png";
import IconGift from "@@/assets/icons/dashboardGift.png";
import IconOne from "@@/assets/icons/dashboardOne.png";
import IconTrendingUp from "@@/assets/icons/dashboardTrendingUp.png";
import IconArrowRight from "@@/assets/icons/menu_arrow_right.png";
import IconArrowDown from "@@/assets/icons/menu_arrow_down.png";
import PageLayout from "@@/layout";

const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  background-color: #07001b;
  width: 100%;
  font-size: 20px;
  line-height: 27px;
  font-weight: 800;
  
  .main_container{
    margin: 60px 120px 0 40px;
    color: #FFFFFF;
    
    .card{
      min-width: 1000px;
      
      .card_grey{
        opacity: 0.45;
      }
      
      .card_container{
        background: #201c31;
        border: 1px solid #464255;
        border-radius: 8px;
        width: 50%;
        padding: 25px;

        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
        
        .header{
          
          .header_left{
            .header_left1{
              width: 25px;
            }
          }
          
          .header_center{
            .header_center1{
              width: 19px;
              height: 18px;
            }
          }
          .header_right_div{
            width: 20px;
            
            .header_right{
              width: 6px;
              height:12px;
            }
            .header_right0{
              width: 12px;
              height:6px;
            }
          }
        
        }

        .content{
          font-size: 26px;
          line-height: 36px;
          .content1{
          }
          .content2{

            font-weight: 600;
          }
        }
        
        .bottom{
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: gray;
          
          .transaction_right2{
            width: 10px;
            height: auto;
          }
          
          .bottom_right{
            height: 45px;
          }
      
        }
      }
    }
    
    .detail{
      min-width: 1000px;

    }
    

  }
  
  
`;

type Props = {};
export default (props: Props) => {
    const [showBalance, setShowBalance] = useState(true);
    const [userInfo, setUserInfo] = useState<any>();
    const [dashboardData, setDashboardData] = useState<any>();
    const [dataList,setDataList] = useState<any[]>([])

    const initUser = async () => {
        setUserInfo(await cacheUserInfo())
    }

    const initDashboardData = async () => {
        const res = await getDashboardOverview()
        if(res?.code === 200){
            setDashboardData(res?.data ?? {})
        }
    }

    const getDataList = async () => {
        const res = await getBalanceHistory({page:1,size:2});
        res.total = res?.data?.total;
        res.data = res?.data?.list ?? [];
        if(res?.code === 200){
            setDataList(res?.data)
        }
    }

    useEffect(() => {
        initUser()
        initDashboardData();
        getDataList()

    }, []);

    return (
        <PageLayout>
            <div className="main_container flex flex-col gap-[20px]">
                <div className="title">Dashboard</div>
                <div className="card flex flex-row gap-[20px]">
                    <div  onClick={()=>setShowBalance(true)} className={`card_container flex flex-col gap-[15px] ${showBalance ? '':'card_grey'}`}>
                        <div className="header flex flex-row justify-between items-center">
                            <div className="header_left flex flex-row gap-[25px]">
                                <img src={IconTrendingUp} alt="header_left1" className="header_left1"/>
                                <div className="header_left2">Balance</div>
                            </div>
                            <div className={'header_right_div flex flex-row justify-center items-center'}>
                                <img src={showBalance ? IconArrowDown : IconArrowRight} alt="header_right" className={`header_right cursor-pointer ${showBalance ? 'header_right0': 'header_right' }`}/>
                            </div>

                        </div>
                        <div className="content flex flex-row gap-[10px]">
                            {/*<div className="content1">CAD </div>*/}
                            {/*<div className="content2">${(dashboardData?.creditBalance ?? 0).toFixed(2)}</div>*/}
                            <div className="content2">{(dashboardData?.creditBalance ?? 0).toFixed(2)} Points</div>
                        </div>
                        <div className="bottom flex flex-row justify-between items-center">
                            <div className="bottom_left flex flex-col gap-[15px]">
                                {dataList.map((item,index)=>(
                                    // <div key={index} className="data_item flex flex-row justify-between">
                                    //     <div className="left">Transaction ID#{item?.id}</div>
                                    //     <div className="right flex flex-row justify-between gap-[100px]">
                                    //         <div className={item?.amount >= 0 ? "right1 currency_green":"right1 currency_red"}>{item?.amount >= 0 ? "+":""}{item?.amount} {merchantCurrency()}</div>
                                    //         <div className="right2">{conversionUtcToDate(item?.createdAt)}</div>
                                    //     </div>
                                    // </div>
                                    <div key={index} className="transaction flex flex-row gap-[15px]">
                                        <div className="transaction_left">Transaction #{item?.id}</div>
                                        <div className="transaction_right flex flex-row gap-[5px]">
                                            <div className="transaction_right1">{item?.amount >= 0 ? "+":""}{item?.amount} {merchantCurrency()}</div>
                                            <img src={item?.amount >= 0 ?IconCaretUp:IconCaretDown} alt="transaction_right2" className="transaction_right2"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/*<img src={IconChart1} alt="bottom_right" className="bottom_right"/>*/}
                        </div>
                    </div>

                    <div onClick={()=>setShowBalance(false)} className={`card_container flex flex-col gap-[15px] ${showBalance ? 'card_grey':''}`}>
                        <div className="header flex flex-row justify-between items-center" >
                            <div className="header_left flex flex-row gap-[25px]">
                                <img src={IconGift} alt="header_left1" className="header_left1"/>
                                <div className="header_left2">Rewards</div>
                            </div>
                            <div className="header_center flex flex-row gap-[5px] items-center">
                                {/*<img src={IconOne} alt="header_left1" className="header_center1"/>*/}
                                {/*<div className="header_center2">ONE token</div>*/}
                            </div>
                            <div className={'header_right_div flex flex-row justify-center items-center'}>
                                <img src={showBalance ?  IconArrowRight: IconArrowDown} alt="header_right" className={`header_right cursor-pointer ${showBalance ? 'header_right': 'header_right0' }`}/>
                            </div>
                        </div>
                        <div className="content flex flex-row gap-[10px]">
                            {/*<div className="content1">CAD </div>*/}
                            {/*<div className="content2">${(dashboardData?.tokenBalance ?? 0).toFixed(2)}</div>*/}
                            <div className="content2">{(dashboardData?.tokenBalance ?? 0).toFixed(2)} Points</div>
                        </div>
                        <div className="bottom flex flex-row justify-between items-center">
                            <div className="bottom_left flex flex-col gap-[15px]">
                            </div>
                            {/*<img src={IconChart2} alt="bottom_right" className="bottom_right"/>*/}
                        </div>
                    </div>
                </div>

                <div className="detail">
                    {showBalance ? <BalanceCard /> : <RewardsCard />}
                </div>
            </div>
        </PageLayout>
  );
};
