import React, {useEffect, useState} from "react";
import {  Header } from "@@/components";
import Popup from "reactjs-popup";
import styled from "styled-components";
import Button from "@@/components/common/Button";
import InputBlock from "@@/components/common/InputBlock";
import LeftMenu from "@@/components/account/LeftMenu";
import {getApplyDetail, getUserInfo, storeApply, storeUpdate, uploadFile} from "@@/utils/request/api";
import MainContent from "@@/components/layout/Main";
import {
    cacheStoreInfo,
    clearAccount,
    data_dictionary, delLocalStoreInfo,
    empty,
    form_validation,
    form_value_validation,
    IconX, IconX0,
    json_to_obj,
    randomNum, setLocalUserInfo
} from "@@/utils/function";
import IconLock from "@@/assets/icons/account_lock.png";
import IconUser from "@@/assets/icons/account_user.png";
import IconChart from "@@/assets/icons/account_chart.png";
import IconStatusApproved from "@@/assets/icons/account_status_approved.png";
import IconStatusPending from "@@/assets/icons/account_status_pending.png";
import IconStatusPendingInfo from "@@/assets/icons/account_status_pending_info.png";
import IconStatusRefused from "@@/assets/icons/account_status_refused.png";
import IconStatusRefusedInfo from "@@/assets/icons/account_status_refused_info.png";
import IconMenuClose from "@@/assets/icons/menu_arrow_down.png";
import IconMenuOpen from "@@/assets/icons/menu_arrow_up.png";
import {firebaseUpdatePassword} from "@@/service/firebaseUser";
import PopupAlert from "@@/pages/PopupAlert";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import toast from "react-hot-toast";
import {Cascader, Select,Input,Upload} from 'antd';
import WorldTree from "@@/utils/world-area/world-tree.json";
import PhoneAreaCode from "@@/utils/world-area/phone_area_code.json";
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import AntSelectBlock from "@@/components/common/AntSelectBlock";
import AntInputBlock from "@@/components/common/AntInputBlock";
import PageLayout from "@@/layout";
const { Option } = Select;
const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #07001b;
  width: 100%;

  .main_container{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin: 60px 120px 0 40px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;

    .title{
      font-size: 20px;
      line-height: 27px;
      font-weight: 800;
    }
    .account_status{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      background: #201c31;
      border-radius: 8px;
      padding: 0 24px;

      .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .left_icon{
          width: 24px;
          height: 24px;
        }

      }

      .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .right_status_icon{
          width: 21px;
          height: 21px;
        }
        .right_status_icon1{
          width: 12px;
          height: 6px;
        }
        .right_statusI_icon{
          width: 15px;
          height: 15px;
        }
        .approved{
          color: #04FF0E;
        }
        .pending{
          color: #ffffff;
        }
        .refused{
          color: #F9B400;
        }
      }
    }

    .account_content{
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #110e21;
      border-radius: 8px;
      padding: 32px 0;

      .first_last{
        display: flex;
        flex-direction: row;
        gap: 15px;
      }

      .ant_error{
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding-top: 6px;
        color: #FF0000;
      }
    }

    .banner_img{
      width: 492px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background: #242034;
        border: 1px dashed rgba(255, 255, 255, 0.4);
        border-radius: 8px;
        padding: 30px 0;

        .upload_btn{
          background: #ffffff;
          //width: 50px;
          border: 1px solid #FFFFFF;
          border-radius: 8px;
          padding: 8px 43px;

        }

        .upload_btn{
          border: 1px solid #FFFFFF;
          border-radius: 8px;
          padding: 8px 43px;
          //background: linear-gradient(179.71deg, #EFCDFB 0.51%, #6A84FF 202.63%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          //background-clip: text;
          text-fill-color: transparent;
        }

        .upload_btn:hover {
          //background-color: #3eb57f;
        }

        .upload_btn input[type="file"] {
          display: none;
        }
      }

      .bottom{
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 15px;
      }
    }

    .select_block{
      margin: 0 0 6px 0;

      .title{
        height: 21px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.8);
        margin: 0 0 5px;
      }

      .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper{
        padding: 0 11px!important;
      }
      .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper input{
        color: rgba(255, 255, 255, 0.8);
      }
      
      .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper input::placeholder{
        color: rgba(255, 255, 255, 0.2);
      }
      .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper input:-internal-autofill-previewed,
      .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper input:-internal-autofill-selected {
        -webkit-text-fill-color:rgba(255, 255, 255, 0.8);
        transition: background-color 5000s ease-out 0.5s;
      }
    }
    .image_block{
      width: 492px;
      display: flex;
      flex-direction: column;
      margin: 0 0 6px 0;

      .title{
        height: 21px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.8);
        margin: 0 0 5px;
      }
    }
    
    .cascader_block{
      margin: 0 0 6px 0;

      .title{
        height: 21px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.8);
        margin: 0 0 5px;
      }
    }
    .ant-select-dropdown{
      width: 490px;
    }

    //.ant-select-dropdown {
    //  //background-color: #28233C;
    //  background-color: red;
    //
    //  .ant-select-item{
    //    color: #ffffff;
    //  }
    //}
    
  }

`;

type Props = {};
export default (props: Props) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [popupMsg,setPopupMsg] = useState('')
    const btnStyle = {margin:'27px 0 0 0', padding:'10px 67px', width:'100%', font_size:'13px', line_height:'18px', font_weight:'400',background: '#267FF5',border_radius: '8px'}
    const antInputCss = {height:34,background:'#242034',background_image:'linear-gradient(324.05deg, #413e50 -8.98%, #413e50 31.12%, #413e50 98.56%)'}
    const antInputCss1 = {width:220,height:34,background:'#242034',background_image:'linear-gradient(324.05deg, #413e50 -8.98%, #413e50 31.12%, #413e50 98.56%)'}
    const antInputCss2 = {width:120,height:34,background:'#242034',background_image:'linear-gradient(324.05deg, #413e50 -8.98%, #413e50 31.12%, #413e50 98.56%)'}
    const selectCss = {width:'490px',height:'36px'}
    const selectCss1 = {width:'220px',height:'36px'}
    const selectCss2 = {width:'350px',height:'36px'}
    const [userStatus,setUserStatus] = useState('none');// none approved waiting refused
    const [openTime,setOpenTime] = useState('');
    const [closeTime,setCloseTime] = useState('');
    const [phoneCode,setPhoneCode] = useState('+1');
    const [phoneNum,setPhoneNum] = useState('');

    type PropsFormData = {
        [key: string]: any;
    };

    const initFormData = {
        storeCategory:'',
        storeName:'',
        storeDesc:'',
        logo:'',
        bannerSmall:'',
        bannerMedium:'',
        bannerLarge:'',
        storePictures:[],
        openTime:'',
        closeTime:'',
        contactEmail:'',
        contactPhone:'',
        website:'',
        isParkingFree:'',
        storeStatus:'',
        floor:'',
        postalCode:'',
        streetName:'',
        city:'',
        province:'',
        country:'',
    }

    const form1Rules:PropsFormData = {
        logo:[
            {required:true,message:'Please provide your logo image to complete the registration.'},
        ],
        bannerSmall:[
            {required:true,message:'Please provide your small card photo to complete the registration.'},
        ],
        bannerMedium:[
            {required:true,message:'Please provide your list view photo to complete the registration.'},
        ],
        bannerLarge:[
            {required:true,message:'Please provide your merchant detail page photo to complete the registration.'},
        ],
        storeName:[
            {required:true,message:'Please enter store name.'},
            {minLen:6,message:'Please enter a store name with a minimum of 6 characters.'},
            {maxLen:50,message:'The store name should not exceed 50 characters. Please shorten it.'},
        ],
        storeCategory:[
            {required:true,message:'The store category field is required. Please choose a category.'},
        ],
        storeDesc:[
            {maxLen:300,message:'The store description should not exceed 300 characters. Please shorten it.'},
        ],
        postalCode:[
            {required:true,message:'The postal code field is required.'},
            {maxLen:20,message:'Please provide a valid postal code.'},
            // {reg:/^[A-Za-z0-9]{6}$/,message:'Please provide a valid six-character postal code.'},
        ],
        streetName:[
            {required:true,message:'The street name field is required. Please provide a street name.'},
            {maxLen:300,message:'The street name should not exceed 300 characters. Please shorten it.'},
        ],
        floor:[
            {maxLen:300,message:'The Unit/Suite Number should not exceed 300 characters. Please shorten it.'},
        ],
        openTime:[
            {required:true,message:'The open time field is required. Please choose an open time.'},
        ],
        closeTime:[
            {required:true,message:'The close time field is required. Please choose an close time.'},
        ],
        // businessHours:[
        //     {required:true,message:'Please enter store name.'},
        //     {maxLen:50,message:'The store name should not exceed 50 characters. Please shorten it.'},
        // ],
        phoneNum:[
            {required:true,message:'The contact phone field is required. Please provide a phone number.'},
            {reg:/^\d{9,11}$/,message:'The phone number provided is not in a valid format. Please enter a valid phone number'},
        ],
        contactEmail:[
            {required:true,message:'The contact email field is required. Please provide a Email address.'},
            {reg:/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,message:'The Email address provided is not in a valid format. Please enter a valid Email address.'},
            {maxLen:50,message:'The contact email should not exceed 50 characters.'},
        ],
        website:[
            {reg:/^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,message:'The website link provided is not in a valid format. Please enter a valid website link.'},
            {maxLen:300,message:'The website should not exceed 300 characters. Please shorten it.'},
        ],
        isParkingFree:[
            {required:true,message:'The parking status field is required. Please select whether parking is free or not.'},
        ],
        storeStatus:[
            {required:true,message:'The store status field is required. Please choose a store status.'},
        ],
        storePictures:[
            {required:true,message:'Please upload an image of your store.'},
        ],
    }


    const [fileLogo, setFileLogo] = useState<UploadFile[]>([]);
    const [fileSmallCardPhoto, setFileSmallCardPhoto] = useState<UploadFile[]>([]);
    const [fileListViewPhoto, setFileListViewPhoto] = useState<UploadFile[]>([]);
    const [fileMerchantDetailPagePhoto, setFileMerchantDetailPagePhoto] = useState<UploadFile[]>([]);
    const [fileStoreImages, setFileStoreImages] = useState<UploadFile[]>([]);
    const [formData, setFormData] = useState<PropsFormData>(initFormData);
    const [formErrorData, setFormErrorData] = useState<PropsFormData>({})
    const [data, setData] = useState<any>({});
    const [refreshNum, setRefreshNum] = useState(1);
    const [editNum, setEditNum] = useState(1);
    const [editNum1, setEditNum1] = useState(1);
    const [isShowAccount1, setIsShowAccount1] = useState(false);
    const [isShowAccount2, setIsShowAccount2] = useState(false);
    const [isShowAccount3, setIsShowAccount3] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');



    const openAccountPopupAlert = (msg:string) => {
        setOpenPopup(true);
        setPopupMsg(msg)
    }

    const openPasswordPopupAlert = (msg:string) => {
        setOpenPopup(true);
        setPopupMsg(msg)
    }


    const onChangeCascader = (value:any) => {
        setFormValue('country',value[0] ?? '')
        setFormValue('province',value[1] ?? '')
        setFormValue('city',value[2] ?? '')
    };

    const onChangeUploadLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileLogo(newFileList);
    };
    const onChangeUploadSmallCardPhoto: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileSmallCardPhoto(newFileList);
    };
    const onChangeUploadListViewPhoto: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileListViewPhoto(newFileList);
    };
    const onChangeUploadMerchantDetailPagePhoto: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileMerchantDetailPagePhoto(newFileList);
    };
    const onChangeUploadStoreImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileStoreImages(newFileList);
        // setFormValue('storePictures',newFileList)
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };


    const beforeUploadFile = (file: RcFile) => {
        const isAllowType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isAllowType) {
            toast.error('You can only upload JPG/PNG file!');
            return Upload.LIST_IGNORE
        }
        const isAllowSize = file.size / 1024 < 300;
        if (!isAllowSize) {
            toast.error('The file size exceeds the limit. Please select a file that is within 300KB for uploading. ');
            return Upload.LIST_IGNORE
        }
        return isAllowType && isAllowSize;
    };

    const beforeUploadStoreImage = (file: RcFile) => {
        const isAllowType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isAllowType) {
            toast.error('You can only upload JPG/PNG file!');
            return Upload.LIST_IGNORE
        }
        const isAllowSize = file.size / 1024/1024 < 1;
        if (!isAllowSize) {
            toast.error('File Size Limit: The store image size should typically not exceed a certain maximum file size, 1MB (megabyte).');
            return Upload.LIST_IGNORE
        }
        return isAllowType && isAllowSize;
    };

    const handleUploadFile = async (options:any,file='logo') => {
        let params = new FormData();
        params.append("file", options.file);
        params.append("content_type", 'multipart/form-data');
        const res1 = await uploadFile(params);
        if(res1?.code !== 200){
            toast.error(res1?.msg ?? 'Failed to upload');
            return false;
        }
        const imgurl = res1?.data?.url;
        if( !imgurl || imgurl?.length <= 0 ){
            toast.error('img url does not exist!');
            return false;
        }

        options.onSuccess(res1, options.file);
        setFormValue(file,imgurl)
    }
    const handleUploadStoreImages = async (options:any) => {
        let params = new FormData();
        params.append("file", options.file);
        params.append("content_type", 'multipart/form-data');
        const res1 = await uploadFile(params);
        if(res1?.code !== 200){
            toast.error(res1?.msg ?? 'Failed to upload');
            return false;
        }
        const imgurl = res1?.data?.url;
        if( !imgurl || imgurl?.length <= 0 ){
            toast.error('img url does not exist!');
            return false;
        }
        setFormValue('storePictures',fileStoreImages)
        options.onSuccess(res1, options.file);
    }


    const handleChangePassword = async () => {
        if( newPassword?.length <= 0 ){
            openPasswordPopupAlert('Please enter new password!');
            return false;
        }
        if( confirmPassword?.length <= 0 ){
            openPasswordPopupAlert('Please confirm new password');
            return false;
        }
        if( newPassword !== confirmPassword ){
            openPasswordPopupAlert('The new password and confirm password fields do not match. Please ensure they are the same.');
            return false;
        }

        const res = await firebaseUpdatePassword(newPassword);
        if( res?.code !== 200 ){
            openPasswordPopupAlert(res?.msg);
            if( res?.code === 403 ){
                clearAccount()
                window.location.href = '/login'
                return false;
            }
            return false;
        }
        openPasswordPopupAlert('Change success!');
    }


    const resetFormErrorDataValue = (key:string) => {
        const data = formErrorData;
        data[key] = {error:false,message:''}
        setFormErrorData(data)
        setEditNum1(editNum1+1)
    }

    const validateFormValue = (key:string) => {
        const data = formErrorData;
        data[key] = form_value_validation(formData[key],form1Rules[key] ?? [])
        setFormErrorData(data)
        setEditNum1(editNum1+1)
    }

    const setFormValue = (key:any,value:any) => {
        resetFormErrorDataValue(key)
        const form = formData;
        form[key] = value;
        setFormData(form)
        setEditNum(editNum + 1)
    }
    
    const onChangeSelectOpenTime = (value:string)=>{
        setOpenTime(value)
        setFormValue('openTime',value)
        setFormValue('businessHours',value+' - '+closeTime)
    }

    const onChangeSelectCloseTime = (value:string)=>{
        setCloseTime(value)
        setFormValue('closeTime',value)
        setFormValue('businessHours',openTime +' - '+value)


    }
    const onChangeSelectPhoneArea = (value:string)=>{
        setPhoneCode(value)
        setFormValue('contactPhone',value+' '+phoneNum)
    }
    const onChangeInputPhoneNum = (item:any)=>{
        const value = item.target.value.slice(0,12)
        setPhoneNum(value);
        setFormValue('phoneNum',value)
        setFormValue('contactPhone',phoneCode+' '+value)
    }

    const submitApply = async () => {
        const {validate,validateData} = form_validation(formData,form1Rules)
        if(!validate){
            toast.error('Error! A problem has been occurred while submitting your data.');
            setFormErrorData(validateData)
            return false
        }


        const Images = fileStoreImages
        for (const key in fileStoreImages) {
            const value = fileStoreImages[key]
            Images[key] = {
                uid: value.uid,
                name: value.name,
                status: 'done',
                url: !value.url ? value.response.data.url : value.url
            }
        }
        setFormValue('storePictures',Images)

        if (userStatus === 'approved'){
            const res = await storeUpdate(formData);
            if(res?.code === 200){
                delLocalStoreInfo()
                await cacheStoreInfo();
                toast.success('Save succeeded!');
                setRefreshNum(refreshNum + 1)
                // window.location.reload()
            }
            else{
                toast.error(res?.msg ?? 'Error');
                return false;
            }
        }
        else{
            const res = await storeApply(formData);
            if(res?.code === 200){
                toast.success('Submit succeeded!');
                setRefreshNum(refreshNum + 1)
            }
            else{
                toast.error(res?.msg ?? 'Error');
                return false;
            }
        }




    }

    const closePopupChangePassword = () => {
        setOpenPopup(false)
    }
    const openPopupChangePassword = () => {
        setOpenPopup(true)
    }


    const getApplyInfo = async () => {
        const res = await getApplyDetail();
        console.log('res222',res);
        if(res?.code !== 200){
            setUserStatus('none')
            return false;
        }

        if(!res?.data || res?.data?.length <= 0){
            setUserStatus('none')
            return false
        }

        if(res?.data?.approved === true){
            setUserStatus('approved')
        }
        else{
            if(res?.data?.latestApplication?.status === 1){
                setUserStatus('waiting')
            }
            // else if(res?.data?.latestApplication?.status === 2){
            //     setUserStatus('approved')
            // }
            else if(res?.data?.latestApplication?.status === 3){
                setUserStatus('refused')
            }
            else{
                setUserStatus('none')
            }
        }

        const fdata = res?.data
        const phone_code = res?.data?.contactPhone.split(' ')[0]
        const phone_num = res?.data?.contactPhone.split(' ')[1]
        const open_time = !res?.data?.businessHours.split(' - ')[0] ? null : res?.data?.businessHours.split(' - ')[0]
        const close_time = !res?.data?.businessHours.split(' - ')[1] ? null : res?.data?.businessHours.split(' - ')[1]
        setData(res?.data)
        fdata.isParkingFree = 'false'
        fdata.storeStatus = 'NORMAL'
        // fdata.isParkingFree = !fdata.isParkingFree ? 'false':'true'
        setPhoneCode(phone_code)
        fdata.phoneCode = phone_code
        setPhoneNum(phone_num)
        fdata.phoneNum = phone_num
        setOpenTime(open_time)
        fdata.openTime = open_time
        setCloseTime(close_time)
        fdata.closeTime = close_time

        setFormData(fdata)
        !empty(res?.data?.logo) &&
        setFileLogo([{
            uid: randomNum(1000000000,3000000000).toString(),
            name: 'logo.png',
            status: 'done',
            url: res?.data?.logo
        }])
        !empty(res?.data?.bannerSmall) &&
        setFileSmallCardPhoto([{
            uid: randomNum(3000000000,5000000000).toString(),
            name: 'bannerSmall.png',
            status: 'done',
            url: res?.data?.bannerSmall
        }])
        !empty(res?.data?.bannerMedium) &&
        setFileListViewPhoto([{
            uid: randomNum(5000000000,7000000000).toString(),
            name: 'bannerMedium.png',
            status: 'done',
            url: res?.data?.bannerMedium
        }])
        !empty(res?.data?.bannerLarge) &&
        setFileMerchantDetailPagePhoto([{
            uid: randomNum(7000000000,9999999999).toString(),
            name: 'bannerLarge.png',
            status: 'done',
            url: res?.data?.bannerLarge
        }])
        if(!empty(res?.data?.storePictures)){
            // setFileStoreImages(json_to_obj(res?.data?.storePictures))
            setFileStoreImages(json_to_obj(res?.data?.storePictures))
        }
    }

    useEffect(() => {
        setNewPassword('')
        setConfirmPassword('')
        getApplyInfo()
    }, [refreshNum]);

    useEffect(() => {
        setNewPassword('')
        setConfirmPassword('')
        getApplyInfo()
    }, []);

    useEffect(() => {
        setFormData(formData)
    }, [editNum])

    useEffect(() => {
        setFormErrorData(formErrorData)
    }, [editNum1])



    return (
        <PageLayout
            activeNav={'account'}
        >
            <Popup
                open={openPopup}
                closeOnDocumentClick={false}
                onClose={()=>setOpenPopup(false)}
                overlayStyle = {{ background: 'rgba(0,0,0,0.8)' }}
            >
                <PopupAlert closePopup={()=>setOpenPopup(false)} msgContent={popupMsg}/>
            </Popup>
            <FormContent>
                <div className="main_container">
                    <div className="title">My Account</div>

                    <div className="account_status">
                        <div className="left">
                            <img src={IconChart} alt="left_icon" className="left_icon"/>
                            <div className="label">Account Status</div>
                        </div>


                        <div className="right">
                            {userStatus !== 'none' && (
                                <>
                                </>
                            )}
                            {userStatus === 'approved' && (
                                <>
                                    <img src={IconStatusApproved} alt="right_status_icon" className="right_status_icon"/>
                                    <div className="approved">Approved</div>
                                </>

                            )}
                            {userStatus === 'waiting' && (
                                <>
                                    <img src={IconStatusPending} alt="right_status_icon" className="right_status_icon"/>
                                    <div className="pending">Pending</div>
                                    <Tooltip id="waiting" place="top"
                                             style={{ backgroundColor: "#D6F3FB", color: "#3A6673",padding:"20px",fontSize:'16px',borderRadius:'8px'  }}
                                    />
                                    <img
                                        data-tooltip-id="waiting"
                                        data-tooltip-content={'MetaBlox Team is reviewing your application.Please wait...'}
                                        src={IconStatusPendingInfo} alt="right_statusI_icon" className="right_statusI_icon cursor-pointer"/>
                                </>
                            )}
                            {userStatus === 'refused' && (
                                <>
                                    <img src={IconStatusRefused} alt="right_status_icon" className="right_status_icon"/>
                                    <div className="reused">Reused</div>
                                    <Tooltip id="refused" place="top"
                                             style={{ backgroundColor: "#FDF2D1", color: "#644E18",padding:"20px",fontSize:'16px',borderRadius:'8px'  }}
                                    />
                                    <img
                                        data-tooltip-id="refused"
                                        data-tooltip-content={data.latestApplication.reason}
                                        src={IconStatusRefusedInfo} alt="right_statusI_icon" className="right_statusI_icon cursor-pointer"/>
                                </>
                            )}

                        </div>

                    </div>

                    <div className="account_status cursor-pointer" onClick={()=>{setIsShowAccount2(!isShowAccount2)}}>
                        <div className="left">
                            <img src={IconUser} alt="left_icon" className="left_icon"/>
                            <div className="label">Store Settings</div>
                        </div>
                        <div className="right">
                            <img src={isShowAccount2 ? IconMenuOpen : IconMenuClose} alt="right_status_icon1" className="right_status_icon1"/>
                        </div>
                    </div>
                    {isShowAccount2 &&
                        <div className="account_content">
                            <div className="image_block">
                                <div className="title">Logo *</div>
                                <div className="">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={1}
                                        rotationSlider>
                                        <Upload
                                            beforeUpload={beforeUploadFile}
                                            listType="picture-card"
                                            fileList={fileLogo}
                                            customRequest={(options)=>handleUploadFile(options)}
                                            onChange={onChangeUploadLogo}
                                            onPreview={onPreview}
                                            maxCount={1}
                                            multiple={false}
                                            onRemove={()=>{
                                                setFormValue('logo','')
                                            }}
                                            disabled={userStatus === 'waiting'}
                                            // progress={{strokeColor:'#EFCDFB',strokeWidth:2}}
                                        >
                                            {fileLogo.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {formErrorData.logo?.error && formErrorData.logo?.message &&
                                    <div className={'ant_error'}>{formErrorData.logo?.message}</div>
                                }
                            </div>

                            <AntInputBlock
                                title='Store name *'
                                antInputPlaceholder={'Please enter your Store name'}
                                value={formData.storeName}
                                antInputOnChange={(item:any)=>setFormValue('storeName',item.target.value.slice(0,50))}
                                // antInputOnBlur={()=>resetFormErrorDataValue('storeName')}
                                antInputCss={antInputCss}
                                antInputAllowClear={true}
                                disabled={userStatus === 'waiting'}
                                antInputOnBlur={()=>validateFormValue('storeName')}
                                errorData={formErrorData.storeName}
                            />
                            <AntSelectBlock
                                title={'Store Category *'}
                                selectCss={selectCss}
                                placeholder={'Please select Store Category'}
                                defaultValue={formData.storeCategory === '' ? null : formData.storeCategory}
                                allowClear={true}
                                disabled={userStatus === 'waiting'}
                                onChange={(value:string)=>setFormValue('storeCategory',value)}
                                fieldNames={{ label: 'name', value: 'code' }}
                                options={data_dictionary('STORE.CATEGORY')}
                                errorData={formErrorData.storeCategory}
                                antSelectOnBlur={()=>validateFormValue('storeCategory')}
                            />

                            <AntInputBlock
                                title={'Store Description(Optional)'}
                                antInputPlaceholder={'Please enter Store Desc'}
                                antInputCss={antInputCss}
                                antInputOnChange={(item:any)=>setFormValue('storeDesc',item.target.value.slice(0,300))}
                                value={formData.storeDesc}
                                antInputAllowClear={true}
                                disabled={userStatus === 'waiting'}
                                antInputOnBlur={()=>validateFormValue('storeDesc')}
                                errorData={formErrorData.storeDesc}
                            />
                            <div className={'cascader_block'}>
                                <div className={'title'}>Country/Province/City *</div>
                                <Cascader
                                    defaultValue={formData.country==='' ? [] : (formData.province === '' ? [formData.country] : (formData.city === '' ? [formData.country,formData.province] : [formData.country,formData.province,formData.city]))}
                                    style={selectCss}
                                    bordered={false}
                                    options={WorldTree}
                                    onChange={onChangeCascader}
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    placeholder="Please select Country/Province/City"
                                    clearIcon={IconX0}
                                />
                            </div>
                            <AntInputBlock
                                title={'Postal Code *'}
                                antInputPlaceholder={'Please enter'}
                                antInputCss={antInputCss}
                                disabled={userStatus === 'waiting'}
                                antInputOnChange={(item:any)=>setFormValue('postalCode',item.target.value.slice(0,20))}
                                value={formData.postalCode}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('postalCode')}
                                errorData={formErrorData.postalCode}
                            />

                            <AntInputBlock
                                title={'Street Name *'}
                                antInputPlaceholder={'Please enter Street name'}
                                antInputCss={antInputCss}
                                disabled={userStatus === 'waiting'}
                                antInputOnChange={(item:any)=>setFormValue('streetName',item.target.value.slice(0,300))}
                                value={formData.streetName}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('streetName')}
                                errorData={formErrorData.streetName}
                            />
                            <AntInputBlock
                                title={'Unit/Suite Number(Optional)'}
                                antInputPlaceholder={'Please enter Unit/Suite Number'}
                                antInputCss={antInputCss}
                                disabled={userStatus === 'waiting'}
                                antInputOnChange={(item:any)=>setFormValue('floor',item.target.value.slice(0,300))}
                                value={formData.floor}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('floor')}
                                errorData={formErrorData.floor}
                            />

                            <div className={'flex flex-row gap-[50px]'}>
                                <AntSelectBlock
                                    title={'Open Time *'}
                                    selectCss={selectCss1}
                                    placeholder={'Please select Open Time'}
                                    defaultValue={!openTime ? null : openTime}
                                    allowClear={true}
                                    disabled={userStatus === 'waiting'}
                                    onChange={onChangeSelectOpenTime}
                                    fieldNames={{ label: 'name', value: 'code' }}
                                    options={data_dictionary('STORE.BUSINESS_HOURS')}
                                    errorData={formErrorData.openTime}
                                    antSelectOnBlur={()=>validateFormValue('openTime')}
                                />
                                <AntSelectBlock
                                    title={'Close Time *'}
                                    selectCss={selectCss1}
                                    placeholder={'Please select Close Time'}
                                    defaultValue={!closeTime ? null : closeTime}
                                    allowClear={true}
                                    disabled={userStatus === 'waiting'}
                                    onChange={onChangeSelectCloseTime}
                                    fieldNames={{ label: 'name', value: 'code' }}
                                    options={data_dictionary('STORE.BUSINESS_HOURS')}
                                    errorData={formErrorData.closeTime}
                                    antSelectOnBlur={()=>validateFormValue('closeTime')}
                                />
                            </div>

                            <div className={'select_block'}>
                                <div className={'title'}>Contact Phone *</div>
                                <Input bordered={false}
                                       style={{width:'490px',height:'36px'}}
                                       placeholder={'Please enter Contact Phone'}
                                       defaultValue={phoneNum}
                                       value={phoneNum}
                                       onChange={onChangeInputPhoneNum}
                                       allowClear={{clearIcon:IconX}}
                                       onBlur={()=>validateFormValue('phoneNum')}
                                       addonBefore={
                                           <Select bordered={false}
                                                   style={{width:'160px'}}
                                                   defaultValue={!phoneCode ? '+1' : phoneCode}
                                                   disabled={userStatus === 'waiting'}
                                                   onChange={onChangeSelectPhoneArea}
                                                   fieldNames={{ label: 'full_name', value: 'code' }}
                                                   options={PhoneAreaCode}
                                           >
                                           </Select>
                                       }  />
                                {formErrorData.phoneNum?.error && formErrorData.phoneNum?.message &&
                                    <div className={'ant_error'} style={{width:'490px'}}>{formErrorData.phoneNum?.message}</div>
                                }
                            </div>

                            <AntInputBlock
                                title={'Contact Email *'}
                                antInputPlaceholder={'Please enter Contact Email'}
                                antInputCss={antInputCss}
                                disabled={userStatus === 'waiting'}
                                antInputOnChange={(item:any)=>setFormValue('contactEmail',item.target.value.slice(0,50))}
                                value={formData.contactEmail}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('contactEmail')}
                                errorData={formErrorData.contactEmail}
                            />
                            <AntInputBlock
                                title={'Website(Optional)'}
                                antInputPlaceholder={'Please enter Website'}
                                antInputCss={antInputCss}
                                disabled={userStatus === 'waiting'}
                                antInputOnChange={(item:any)=>setFormValue('website',item.target.value.slice(0,300))}
                                value={formData.website}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('website')}
                                errorData={formErrorData.website}
                            />

                            {/*<div className={'flex flex-row gap-[50px]'}>*/}
                            {/*    <AntSelectBlock*/}
                            {/*        title={'Is Parking Free *'}*/}
                            {/*        selectCss={selectCss1}*/}
                            {/*        placeholder={'Please select Is Parking Free'}*/}
                            {/*        defaultValue={formData.isParkingFree}*/}
                            {/*        allowClear={true}*/}
                            {/*        disabled={userStatus === 'waiting'}*/}
                            {/*        onChange={(value:string)=>setFormValue('isParkingFree',value)}*/}
                            {/*        fieldNames={{ label: 'name', value: 'code' }}*/}
                            {/*        options={data_dictionary('GLOBAL.YES_OR_NO')}*/}
                            {/*        errorData={formErrorData.isParkingFree}*/}
                            {/*        antSelectOnBlur={()=>validateFormValue('isParkingFree')}*/}
                            {/*    />*/}
                            {/*    <AntSelectBlock*/}
                            {/*        title={'Store Status *'}*/}
                            {/*        selectCss={selectCss1}*/}
                            {/*        placeholder={'Please select Store Status'}*/}
                            {/*        defaultValue={formData.storeStatus === '' ? null : formData.storeStatus}*/}
                            {/*        allowClear={true}*/}
                            {/*        disabled={userStatus === 'waiting'}*/}
                            {/*        onChange={(value:string)=>setFormValue('storeStatus',value)}*/}
                            {/*        fieldNames={{ label: 'name', value: 'code' }}*/}
                            {/*        options={data_dictionary('STORE.STATUS')}*/}
                            {/*        errorData={formErrorData.storeStatus}*/}
                            {/*        antSelectOnBlur={()=>validateFormValue('storeStatus')}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="image_block">
                                <div className="title">Small Card Photo *</div>
                                <div className="">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={311/110}
                                        rotationSlider>
                                        <Upload
                                            beforeUpload={beforeUploadStoreImage}
                                            listType="picture-card"
                                            fileList={fileSmallCardPhoto}
                                            customRequest={(options)=>handleUploadFile(options,'bannerSmall')}
                                            onChange={onChangeUploadSmallCardPhoto}
                                            onPreview={onPreview}
                                            maxCount={1}
                                            multiple={false}
                                            onRemove={()=>{
                                                setFormValue('bannerSmall','')
                                            }}
                                            disabled={userStatus === 'waiting'}
                                            // progress={{strokeColor:'#EFCDFB',strokeWidth:2}}
                                        >
                                            {fileSmallCardPhoto.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {formErrorData.bannerSmall?.error && formErrorData.bannerSmall?.message &&
                                    <div className={'ant_error'}>{formErrorData.bannerSmall?.message}</div>
                                }
                            </div>
                            <div className="image_block">
                                <div className="title">List View Photo *</div>
                                <div className="">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={1}
                                        rotationSlider>
                                        <Upload
                                            beforeUpload={beforeUploadStoreImage}
                                            listType="picture-card"
                                            fileList={fileListViewPhoto}
                                            customRequest={(options)=>handleUploadFile(options,'bannerMedium')}
                                            onChange={onChangeUploadListViewPhoto}
                                            onPreview={onPreview}
                                            maxCount={1}
                                            multiple={false}
                                            onRemove={()=>{
                                                setFormValue('bannerMedium','')
                                            }}
                                            disabled={userStatus === 'waiting'}
                                            // progress={{strokeColor:'#EFCDFB',strokeWidth:2}}
                                        >
                                            {fileListViewPhoto.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {formErrorData.bannerMedium?.error && formErrorData.bannerMedium?.message &&
                                    <div className={'ant_error'}>{formErrorData.bannerMedium?.message}</div>
                                }
                            </div>
                            <div className="image_block">
                                <div className="title">Merchant Detail Page Photo *</div>
                                <div className="">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={319/195}
                                        rotationSlider>
                                        <Upload
                                            beforeUpload={beforeUploadStoreImage}
                                            listType="picture-card"
                                            fileList={fileMerchantDetailPagePhoto}
                                            customRequest={(options)=>handleUploadFile(options,'bannerLarge')}
                                            onChange={onChangeUploadMerchantDetailPagePhoto}
                                            onPreview={onPreview}
                                            maxCount={1}
                                            multiple={false}
                                            onRemove={()=>{
                                                setFormValue('bannerLarge','')
                                            }}
                                            disabled={userStatus === 'waiting'}
                                            // progress={{strokeColor:'#EFCDFB',strokeWidth:2}}
                                        >
                                            {fileMerchantDetailPagePhoto.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {formErrorData.bannerLarge?.error && formErrorData.bannerLarge?.message &&
                                    <div className={'ant_error'}>{formErrorData.bannerLarge?.message}</div>
                                }
                            </div>
                            <div className="image_block">
                                <div className="title">Store Image *</div>
                                <div className="">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={319/195}
                                        rotationSlider>
                                        <Upload
                                            listType="picture-card"
                                            // listType="picture"
                                            fileList={fileStoreImages}
                                            beforeUpload={beforeUploadStoreImage}
                                            customRequest={handleUploadStoreImages}
                                            onChange={onChangeUploadStoreImage}
                                            onPreview={onPreview}
                                            // onRemove={}
                                            maxCount={10}
                                            disabled={userStatus === 'waiting'}
                                            // progress={{strokeColor:'#EFCDFB',strokeWidth:2}}
                                        >
                                            {fileStoreImages.length < 10 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {formErrorData.storePictures?.error && formErrorData.storePictures?.message &&
                                    <div className={'ant_error'}>{formErrorData.storePictures?.message}</div>
                                }
                            </div>
                            {userStatus === 'none' && (
                                <Button title={'Submit'} SubmitClick={submitApply} cssStyle={btnStyle}/>
                            )}

                            {userStatus === 'refused' && (
                                <Button title={'Submit'} SubmitClick={submitApply} cssStyle={btnStyle}/>
                            )}
                            {userStatus === 'approved' && (
                                <Button title={'Save'} SubmitClick={submitApply} cssStyle={btnStyle}/>
                            )}

                        </div>

                    }

                    <div className="account_status cursor-pointer"  onClick={()=>{setIsShowAccount3(!isShowAccount3)}}>
                        <div className="left">
                            <img src={IconLock} alt="left_icon" className="left_icon"/>
                            <div className="label">Security</div>
                        </div>
                        <div className="right">
                            <img src={isShowAccount3 ? IconMenuOpen : IconMenuClose} alt="right_status_icon1" className="right_status_icon1"/>
                        </div>
                    </div>

                    {isShowAccount3 &&
                        <div className="account_content">
                            <AntInputBlock
                                title={'*New Password'}
                                antInputPlaceholder={'Please enter new password'}
                                antInputCss={antInputCss}
                                value={newPassword}
                                antInputOnChange={(item:any)=>{setNewPassword(item.target.value.slice(0,30))}}
                                antInputAllowClear={true}
                            />
                            <AntInputBlock
                                title={'*Confirm Password'}
                                antInputPlaceholder={'Please confirm new password'}
                                antInputCss={antInputCss}
                                value={confirmPassword}
                                antInputOnChange={(item:any)=>{setConfirmPassword(item.target.value.slice(0,30))}}
                                antInputAllowClear={true}
                            />

                            <div className="btn">
                                <Button title={'Save'} SubmitClick={handleChangePassword} cssStyle={btnStyle}/>
                            </div>

                        </div>
                    }
                </div>
            </FormContent>
        </PageLayout>
    );
};

