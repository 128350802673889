import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const Pane = styled.div<{ weight: number }>`
  flex: ${(props) => props.weight};
`;

type Props = {
  leftWeight: number;
  rightWeight: number;
  children: React.ReactNode[];
};

const Splitscreen = ({ leftWeight, rightWeight, children }: Props) => {
  const [left, right] = children;
  return (
    <Container>
      <Pane weight={leftWeight}>{left}</Pane>

      <Pane weight={rightWeight}>{right}</Pane>
    </Container>
  );
};

export default Splitscreen;
