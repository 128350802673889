import {firebaseAuth as auth} from "@@/config/firebase-config";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
} from "firebase/auth"

// const actionCodeSettings = {
//     // URL you want to redirect back to. The domain (www.example.com) for this
//     // URL must be in the authorized domains list in the Firebase Console.
//     url: 'https://www.example.com/finishSignUp?cartId=1234',
//     // This must be true.
//     handleCodeInApp: true,
//     iOS: {
//         bundleId: 'com.example.ios'
//     },
//     android: {
//         packageName: 'com.example.android',
//         installApp: true,
//         minimumVersion: '12'
//     },
//     dynamicLinkDomain: 'example.page.link'
// };


export const firebaseCreateUserWithEmailAndPassword = async (email:string,password:string) => {
    return await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            return {code:200,msg:'ok',data:userCredential.user}
            // console.log('userCredential',userCredential);
            // console.log('user',user);
            // ...
        })
        .catch((error) => {
            const res = {code:-1,msg:error.code.replace('auth/',''),data:null}
            if(error.code.indexOf("email-already-in-use") != -1){
                res.code = -100;
                res.msg = 'The email has been registered!';
            }
            return res;
        });
}

export const firebaseSignInWithEmailAndPassword = async (email:string,password:string) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            return {code:200,msg:'ok',data:userCredential.user}
        })
        .catch((error) => {
            const res = {code:-1,msg:error.code.replace('auth/',''),data:null}
            if(error.code.indexOf("user-not-found") != -1){
                res.code = -101;
                res.msg = 'Email not registered!';
            }

            if(error.code.indexOf("wrong-password") != -1){
                res.code = -102;
                res.msg = 'Wrong password!';
            }
            return res
        });
}


// export const firebaseSendSignInLinkToEmail = async (email:string) => {
//     return await sendSignInLinkToEmail(auth, email, actionCodeSettings)
//         .then(() => {
//             // The link was successfully sent. Inform the user.
//             // Save the email locally so you don't need to ask the user for it again
//             // if they open the link on the same device.
//             window.localStorage.setItem('emailForSignIn', email);
//             // ...
//         })
//         .catch((error) => {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//             // ...
//         });
// }

// export const firebaseSignInWithEmailLink = async (email:string) => {
//
//
// // Confirm the link is a sign-in with email link.
//     if (isSignInWithEmailLink(auth, window.location.href)) {
//         // Additional state parameters can also be passed via URL.
//         // This can be used to continue the user's intended action before triggering
//         // the sign-in operation.
//         // Get the email if available. This should be available if the user completes
//         // the flow on the same device where they started it.
//         let email = window.localStorage.getItem('emailForSignIn');
//         if (!email) {
//             // User opened the link on a different device. To prevent session fixation
//             // attacks, ask the user to provide the associated email again. For example:
//             email = window.prompt('Please provide your email for confirmation');
//             return false
//         }
//         // The client SDK will parse the code from the link for you.
//         return await signInWithEmailLink(auth, email, window.location.href)
//             .then((result) => {
//                 // Clear email from storage.
//                 window.localStorage.removeItem('emailForSignIn');
//                 // You can access the new user via result.user
//                 // Additional user info profile not available via:
//                 // result.additionalUserInfo.profile == null
//                 // You can check if the user is new or existing:
//                 // result.additionalUserInfo.isNewUser
//             })
//             .catch((error) => {
//                 // Some error occurred, you can inspect the code: error.code
//                 // Common errors could be invalid email and invalid or expired OTPs.
//             });
//     }
// }


export const firebaseLoginByEmailAndPassword = async (email:string,password:string) => {
    const res = await firebaseCreateUserWithEmailAndPassword(email, password)
    if(res?.code === -100){
        return await firebaseSignInWithEmailAndPassword(email, password)
    }
    else {
        return res;
    }
}

export const firebaseEmailSignIn = async (email:string,password:string) => {
    return await firebaseSignInWithEmailAndPassword(email, password)
}

export const firebaseEmailSignUp = async (email:string,password:string) => {
    return await firebaseCreateUserWithEmailAndPassword(email, password)
}

export const firebaseSendEmailVerification = async () => {
    return await sendEmailVerification(auth?.currentUser!)
        .then((ii) => {
            return {code:200,msg:'ok',data:[]}
            // Email verification sent!
            // ...
        })
        .catch((error) => {
            return {code:-1,msg:error.code.replace('auth/',''),data:null}
        });
}



export const firebaseSendPasswordResetEmail = async (email:string) => {
    return await sendPasswordResetEmail(auth,email)
        .then((ii) => {
            return {code:200,msg:'ok',data:[]}
            // Email verification sent!
            // ...
        })
        .catch((error) => {
            return {code:-1,msg:error.code.replace('auth/',''),data:null}
        });
}
