import { useLocation, Navigate, Outlet } from "react-router-dom";
// import { Navbar } from "./";
// import useAuth from "../hooks/useAuth";
import {getLocalStoreInfo1, getLocalUserInfo} from "@@/utils/function";
import { useState} from "react";

type Props = {
    allowedRoles:any[]
}
const RequireAuth = ({allowedRoles = []}:Props) => {
    const user = getLocalUserInfo();
    const location = useLocation();
    const [applyStatus, setApplyStatus] =  useState(getLocalStoreInfo1()?.approved);

    if(!user){
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return allowedRoles?.includes(user?.role)
      ?
      (
          applyStatus
              ? (
                  <>
                      {/*<Navbar />*/}
                      <Outlet />
                  </>
              )
              : ( <Navigate to="/settings/account" state={{ from: location }} replace />)

      )
      : <Navigate to="/unauthorized" /*unauthorized*/ state={{ from: location }} replace />;
};

export default RequireAuth;
