import { get, post} from '@@/utils/request/http'
const apiGet = (url:string,p:any)=>get(url, p).then((res:any)=>{return res;}).catch((ee:any)=>{return ee;});
const apiPost = (url:string,p:any,config:any={})=>post(url, p,config).then((res:any)=>{return res;}).catch((ee:any)=>{return ee;});


export const welcome = (p?:any) => apiGet('/user/welcome', p);
export const getDiCtData = (p?:any) => apiGet('/dict/list', p);
export const storeApply = (p:any) => apiPost('/store/apply', p);
export const storeUpdate = (p:any) => apiPost('/store/edit', p);
// export const getStoreDetail = (p?:any) => apiGet('/store/detail', p);
export const getApplyDetail = (p?:any) => apiGet('/store/detail', p);

// credit = balance reward = token
export const getUserInfo = (p?:any) => apiGet('/user/info', p);
export const getDashboardOverview = (p?:any) => apiGet('/dashboard/overview', p);
export const getBalanceHistory = (p?:any) => apiGet('/dashboard/credit/page', p);
export const getRewardHistory = (p?:any) => apiGet('/dashboard/token/page', p);

export const getWifiInfo = (p?:any) => apiGet('/settings/detail', p);
export const settingWiFi = (p:any) => apiPost('/settings/custom', p);

export const getCheckingHistoryList = (p:any) => apiGet('/store/wifi/checking/page', p);
export const getGuestList = (p:any) => apiGet('/store/wifi/guest/page', p);
export const getWifiConnectionsList = (p:any) => apiGet('/store/wifi/status/page', p);


export const uploadFile = (p:any) => apiPost('/file/upload',p,{content_type:'multipart/form-data'});
