import React, {useEffect, useState} from "react";
import styled from "styled-components";
import IconSeeMore from "@@/assets/icons/see_more.png";
import { getRewardHistory } from "@@/utils/request/api";
import {conversionTimestampToDate, conversionUtcToDate, HarmonyTokenSymbol, merchantCurrency} from "@@/utils/function";

type PropsStyled = {
    width?:string,
    height?:string,
};

const CardGroup = styled.div.attrs((props:PropsStyled) => ({
    width: props?.width || "320px",
    height: props?.height || "56px",
}))`
  background-color: #201c31;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  //padding: 24px 16px 10px 26px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;

  .title {
    background: #363246;
    border-radius: 8px 8px 0 0;
    padding: 20px 0 20px 28px;
  }

  .data_list{
    height: 700px;
    overflow-y:scroll;
    background-color: #201c31;

    .data_item{
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      border-bottom: 1px solid #464255;
      margin: 24px 36px 0 29px;
      padding-bottom: 26px;
      .left{
        font-size: 13px;
        line-height: 18px;
      }
      .right{

        .currency_green{
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #66E2A9;
        }
        .currency_red{
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #FF4A4A;
        }

        .right1{
          width: 120px;
        }

        .right2{
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-align: right;
          width: 140px;
          //margin-right: 16px;
          color: rgba(255, 255, 255, 0.5);
        }
      }


    }
  }

  .see_more{
    margin: 47px 0 32px 0;
    cursor: pointer;
    div{
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }
    img{
      width: 10px;
    }
  }

  .the_end{
    margin: 47px 0 32px 0;

    div{
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: #FFFFFF;

    }
  }
`;


const RewardsCard = () => {
    const pageSize = 10;
    const [pageNum,setPageNum] = useState(1)
    const [dataList,setDataList] = useState<any[]>([])
    const [isEnd,setIsEnd] = useState(false)

    const seeMore = async () => {
        setPageNum(pageNum + 1 )
    }

    const getDataList = async () => {
        const res = await getRewardHistory({page:pageNum,size:pageSize});
        res.total = res?.data?.total;
        res.data = res?.data?.list ?? [];
        if(res?.code === 200){
            if(!res?.data || res?.data?.length <= 0 || res?.total <= pageSize*pageNum){
                setIsEnd(true)
            }
            setDataList(dataList.concat(res?.data))
        }
    }
    useEffect(() => {
        getDataList()
    }, [pageNum]);

    useEffect(() => {
        getDataList()
    }, []);
    return (
        <CardGroup>
            <div className="title">Rewards Detail</div>
            <div className="data_list">
                <div className="data_item flex flex-row justify-between">
                    <div className="left font-medium">Customer ID</div>
                    <div className="right flex flex-row justify-between gap-[100px]">
                        <div className='right1 font-medium text-white'>Amount</div>
                        <div className="right2 font-medium text-white">Date and Time</div>
                    </div>
                </div>
                {dataList.map((item,index)=>(
                    <div key={index} className="data_item flex flex-row justify-between">
                        <div className="left">ID#{item?.id}</div>
                        <div className="right flex flex-row justify-between gap-[100px]">
                            <div className={item?.amount >= 0 ? "right1 currency_green":"right1 currency_red"}>{item?.tokenAmount >= 0 ? "+":""}{item?.tokenAmount} {HarmonyTokenSymbol()}</div>
                            <div className="right2">{conversionUtcToDate(item?.createdAt)}</div>
                        </div>
                    </div>
                ))}
            </div>
            {isEnd
                ? (
                    <div className="the_end flex flex-row justify-center items-center" onClick={seeMore}>
                        {/*<div>The End</div>*/}
                    </div>
                )
                : (
                    <div className="see_more flex flex-row justify-center items-center gap-[15px]" onClick={seeMore}>
                        <div>See More</div>
                        <img src={IconSeeMore} alt=""/>
                    </div>
                )
            }

        </CardGroup>

    );
};

export default RewardsCard;
