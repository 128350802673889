import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PageLayout from "@@/layout";
import { Table} from 'antd';
import type { FilterValue,ColumnsType,TablePaginationConfig, SorterResult } from 'antd/es/table/interface';
import AntSearchInputBlock from "@@/components/common/AntSearchInput";
import {getGuestList, getWifiConnectionsList} from "@@/utils/request/api";
import {conversionUtcToDate, timeToFriendly} from "@@/utils/function";
import toast from "react-hot-toast";

const TableGroup = styled.div`
  padding: 60px 120px 0 40px;

  .ant-input-affix-wrapper-focused {
    border-color: #e8e8e8;
    outline: 0;
    box-shadow:0 0 0 0!important;
  }
`;


interface DataType {
    id:number
    memberId: number
    email: string
    createdAt: string
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}
const columns: ColumnsType<DataType> = [
    {
        title: 'No.',
        // dataIndex: 'id',
        // sorter: true,
        // render: (name) => `${name.first} ${name.last}`,
        render: (value, record, index) => `${index + 1}`,
        width: '8%',
    },
    {
        title: 'WiFi Type',
        dataIndex: 'type',
        render: (type) => `${type === 'openroaming' ? 'Open Roaming' : 'Free Wifi'}`,
        width: '10%',
    },
    {
        title: 'WiFi Name',
        dataIndex: 'ssid',
        width: '22%',
    },
    {
        title: 'User Equipment',
        dataIndex: 'hostname',
        width: '20%',
    },
    // {
    //     title: 'signal',
    //     dataIndex: 'signal',
    //     // width: '20%',
    // },
    // {
    //     title: 'signalAvg',
    //     dataIndex: 'signalAvg',
    //     // width: '20%',
    // },
    {
        title: 'Connection Duration',
        dataIndex: 'connectedTime',
        render: (connectedTime) => `${timeToFriendly(connectedTime)}`,
        width: '20%',
    },
    // {
    //     title: 'Offline Time',
    //     dataIndex: 'inactive',
    //     render: (inactive) => `${timeToFriendly(inactive)}`,
    //     // width: '20%',
    // },
    {
        title: 'Connected Time',
        dataIndex: 'createdAt',
        render: (createdAt) => `${conversionUtcToDate(createdAt)}`,
        width: '20%',
    },
];

type Props = {};
export default (props: Props) => {
    const [data, setData] = useState<DataType[]>();
    const [loading, setLoading] = useState(false);
    const [defaultValue, setdefaultValue] = useState(null);
    const [searchKeywords, setSearchKeywords] = useState();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const fetchData = () => {
        setLoading(true);
        getWifiConnectionsList({
            page:tableParams?.pagination?.current ?? 1,
            size:tableParams?.pagination?.pageSize ?? 10,
            ssid:searchKeywords
        }).then((results:any)=>{
            const code = results?.code;
            const total = results?.data?.total;
            const data = results?.data?.list?? [];
            if(code === 200){
                setData(data);
            }
            else{
                toast.error(results?.msg);
            }
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: total,
                },
            });
        })
    };



    const handleInputEnter = () => {
        setSearchKeywords(searchKeywords)
    }

    const handleTableChange = (pagination:TablePaginationConfig,filters:Record<string, FilterValue|null>,sorter:any) => {
        console.log('pagination',pagination);
        console.log('filters',filters);
        console.log('sorter',sorter);
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams),searchKeywords]);

    return (
        <PageLayout activeNav="user">
            <TableGroup className="flex flex-col gap-[20px]">
                <div className="text-[#ffffff]">WiFi Connections</div>
                <AntSearchInputBlock
                    antInputPlaceholder={'Search Wifi Name'}
                    value={searchKeywords}
                    antInputOnChange={(item:any)=>setSearchKeywords(item.target.value.slice(0,50))}
                    antInputOnPressEnter={handleInputEnter}
                />

                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </TableGroup>

        </PageLayout>
    );
};
