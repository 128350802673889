import {firebaseAuth as auth} from "@@/config/firebase-config";
import {
    updatePassword,
} from "firebase/auth"

// const actionCodeSettings = {
//     // URL you want to redirect back to. The domain (www.example.com) for this
//     // URL must be in the authorized domains list in the Firebase Console.
//     url: 'https://www.example.com/finishSignUp?cartId=1234',
//     // This must be true.
//     handleCodeInApp: true,
//     iOS: {
//         bundleId: 'com.example.ios'
//     },
//     android: {
//         packageName: 'com.example.android',
//         installApp: true,
//         minimumVersion: '12'
//     },
//     dynamicLinkDomain: 'example.page.link'
// };



export const firebaseUpdatePassword = async (newPassword:string) => {
    const user = auth.currentUser;
    if(user !== null){
        return await updatePassword(user, newPassword).then((rr) => {
            console.log('rr',rr);
            // Update successful.
            return {code:200,msg:'ok',data:null}

        }).catch((error) => {
            const msg = error.code.replace('auth/','');
            console.log('error',error.msg);
            // auth/requires-recent-login
            if(msg === 'requires-recent-login'){
                return {code:403,msg:msg,data:null}
            }
            return {code:-1,msg:msg,data:null}
        });
    }
    else{
        return {code:-1,msg:'error',data:null}
    }

}
