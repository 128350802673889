import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "@@/components/RequireAuth";
import { routerConfig } from '@@/routerConfig';
import React from "react";
import {ConfigProvider} from "antd";

function App() {
  return (
      <ConfigProvider
          theme={{
              token: {
                  colorPrimary: 'rgb(75,53,171)',
                  colorBgBase:'rgb(32, 28, 49)',
                  colorTextBase:'#ffffff',
              },
          }}
      >
          <BrowserRouter>
              <Routes>
                  {routerConfig.map((item:any,index)=>(
                      item.allowedRoles.length > 0
                          ?
                          <Route key={index} element={<RequireAuth allowedRoles={item.allowedRoles} />}>
                              <Route path={item.path} element={item.element} />
                          </Route>
                          :
                          <Route key={index} path={item.path} element={item.element} />
                  ))}
              </Routes>
          </BrowserRouter>
      </ConfigProvider>

  );
}

export default App;
